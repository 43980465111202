* {
  box-shadow: none !important;
}

:root {
  --black: #16191E;
  --white: #f7f7f7e0;
  --accent: #d715fd;
  --accent-light: #8570f5;
  --wheat: #f5deb3;
}

body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Gilroy', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}

.makeStyles-paper-11,
.makeStyles-paper-11 h2,
.MuiTypography-body1,
.MuiPaper-root,
.MuiPaper-root h2 {
  color: var(--wheat) !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  background-color: #201c76db !important;
  backdrop-filter: blur(5px);
  border-radius: 5px !important;
  border: transparent !important;
}

.MuiCard-root {
  background-color: #201c76db !important;
  backdrop-filter: blur(5px);
  border-radius: 5px !important;
  height: 300px;
  width: 300px;
  border: 1px solid black;
  border-radius: 50px;

}

.MuiButton-contained {
  border-radius: 16px !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy' !important;
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: var(--white);
}

.MuiButton-contained {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.MuiButton-containedPrimary {
  color: var(--white) !important;
  background-color: var(--accent) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #2c2560 !important;
  font-family: 'Gilroy', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}

.wallet-button:hover {
  background-color: black !important;
  color: #757ce8 !important;
}

.card_token_countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgb(24 24 24 / 90%) !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MuiPaper-root.card_token_bg .card_token_countdown,
.card_token_bg .card_token_countdown {
  background-color: #e30333 !important;
  color: #000;
  border-radius: 0 10px 0 10px !important;
  position: relative !important;
  bottom: auto;
  padding: 10px 0;
}

.mar {
  margin-right: 6rem !important;
  margin-top: 4rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.top {
  margin-top: 4rem !important;
}

.card3 {
  display: flex;
}

.MuiPaper-root.card_token_bg .card_token_countdown p,
.card_token_bg .card_token_countdown p {
  color: #000;
}

.card_token_countdown_home p {
  font-weight: 800;
  color: #fff;
  font-size: 2.1rem;
}

.card_token_countdown p {
  font-weight: 800;
  color: #fff;
  font-size: 1.5rem;
}

.countdown_container {
  position: relative;
}

.countdown_container .card_token_countdown {
  background: rgb(18 18 18 / 90%) !important;
}

/* #myVideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
} */



.card_token_countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgb(24 24 24 / 90%) !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MuiPaper-root.card_token_bg .card_token_countdown,
.card_token_bg .card_token_countdown {
  background-color: #6118e9 !important;
  color: rgb(255, 255, 255);
  border-radius: 5px 5px 5px 5px !important;
  position: relative !important;
  bottom: auto;
  padding: 10px 0;
  margin-bottom: 20px;
}

.MuiPaper-root.card_token_bg .card_token_countdown p,
.card_token_bg .card_token_countdown p {
  color: #000;
}

.card_token_countdown_home p {
  font-weight: 800;
  color: #fff;
  font-size: 2.1rem;
}

.card_token_countdown p {
  font-weight: 800;
  color: #fff;
  font-size: 1.5rem;
}

.countdown_container {
  position: relative;
}

.countdown_container .card_token_countdown {
  background: rgb(18 18 18 / 90%) !important;
}

.harvest {
  display: flex;
  flex-direction: column;
}

.MuiPaper-root.card_token_bg::before,
.MuiPaper-root.card_token_bg::after,
.card_token_bg::before,
.card_token_bg::after,
.home_card::after,
.home_card::before {
  content: "";
  top: 50%;
  margin: -30px 0;
  height: 60px;
  width: 20px;
  position: absolute;
  background-size: 20px auto;
  pointer-events: none;
}

.home_card::before {
  left: 0;
}

.home_card::after {
  right: 0;
}

.MuiPaper-root.card_token_bg::before,
.card_token_bg::before {
  left: 0;
  width: 10px;
}

.MuiPaper-root.card_token_bg::after,
.card_token_bg::after {
  right: 0;
  width: 10px;
}

.home_card h2 {
  font-size: 2.5rem;
  margin: 0 0 -1.25rem 0;
  color: #e30333;
}

.modal-zap-estimate {
  margin: 1rem 0 -1rem 0;
}

.modal-zap-estimate p {
  margin: 0;
  text-align: left;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.modal-zap-estimate-input {
  background-color: #021130;
  border-radius: 12px;
  padding: 0.5rem;
}

.modal-zap-estimate-input p {
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.modal_zap_select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_account {
  color: #fff !important;
}

.text-center {
  text-align: center;
}


.modal {
  width: 400px;
  outline: none;
  padding: 32px;
  position: absolute;
  max-width: 100%;
  background-color: #201c76 !important;
}

.modal h2 {
  color: #fff !important;
  text-align: center;
  margin-bottom: 1.5rem;
}

.modal button,
.modal button:hover,
.modal button:focus {
  color: #fff !important;
}

.modal button.wallet-button,
.modal button.wallet-button:hover,
.modal button.wallet-button:focus {
  padding-left: 90px !important;
  margin-bottom: 3rem;

}

.modal button img {
  width: 32px;
}

.modal .wallet-icon {
  width: 65px;
  height: 65px;
  display: flex;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  top: -5px;
}

.modal_root_styled {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
}

.modal_root_backdrop {
  background-color: rgba(0, 0, 0, .75);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.modal_root .modal {
  position: relative;
  width: auto;
}

.modal_account {
  color: #fff !important;
}


body {
  background: transparent;
}

.intro,
.intro a {
  color: #fff;
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }

  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(80px)
  }

  100% {
    transform: translateX(0px)
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}

/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}

.demo a {
  font-family: 'Raleway', sans-serif;
  color: #000;
}

* {
  box-shadow: none !important;
}

:root {
  --black: #16191E;
  --white: #f7f7f7e0;
  --accent: #d715fd;
  --accent-light: #8570f5;
}

body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Gilroy', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

.makeStyles-paper-11,
.makeStyles-paper-11 h2,
.MuiTypography-body1,
.MuiPaper-root,
.MuiPaper-root h2 {
  color: var(--wheat) !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  background-color: #201c76db !important;
  backdrop-filter: blur(5px);
  border-radius: 5px !important;
  border: transparent !important;
}

.MuiCard-root {
  background-color: #201c76db !important;
  backdrop-filter: blur(5px);
  border-radius: 5px !important;
}

.MuiButton-contained {
  border-radius: 6px !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy' !important;
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: var(--white);
}

.MuiButton-contained {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.MuiButton-containedPrimary {
  color: var(--white) !important;
  background-color: var(--accent) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #2c2560 !important;
  font-family: 'Gilroy', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}

.wallet-button:hover {
  background-color: black !important;
  color: #757ce8 !important;
}

.card_token_countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgb(24 24 24 / 90%) !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MuiPaper-root.card_token_bg .card_token_countdown,
.card_token_bg .card_token_countdown {
  background-color: #e30333 !important;
  color: #000;
  border-radius: 0 10px 0 10px !important;
  position: relative !important;
  bottom: auto;
  padding: 10px 0;
}

.MuiPaper-root.card_token_bg .card_token_countdown p,
.card_token_bg .card_token_countdown p {
  color: #000;
}

.card_token_countdown_home p {
  font-weight: 800;
  color: #fff;
  font-size: 2.1rem;
}

.card_token_countdown p {
  font-weight: 800;
  color: #fff;
  font-size: 1.5rem;
}

.countdown_container {
  position: relative;
}

.countdown_container .card_token_countdown {
  background: rgb(18 18 18 / 90%) !important;
}

/* #myVideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
} */



.card_token_countdown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgb(24 24 24 / 90%) !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MuiPaper-root.card_token_bg .card_token_countdown,
.card_token_bg .card_token_countdown {
  background-color: #6118e9 !important;
  color: rgb(255, 255, 255);
  border-radius: 5px 5px 5px 5px !important;
  position: relative !important;
  bottom: auto;
  padding: 10px 0;
  margin-bottom: 20px;
}

.MuiPaper-root.card_token_bg .card_token_countdown p,
.card_token_bg .card_token_countdown p {
  color: #000;
}

.card_token_countdown_home p {
  font-weight: 800;
  color: #fff;
  font-size: 2.1rem;
}

.card_token_countdown p {
  font-weight: 800;
  color: #fff;
  font-size: 1.5rem;
}

.countdown_container {
  position: relative;
}

.countdown_container .card_token_countdown {
  background: rgb(18 18 18 / 90%) !important;
}

.MuiPaper-root.card_token_bg::before,
.MuiPaper-root.card_token_bg::after,
.card_token_bg::before,
.card_token_bg::after,
.home_card::after,
.home_card::before {
  content: "";
  top: 50%;
  margin: -30px 0;
  height: 60px;
  width: 20px;
  position: absolute;
  background-size: 20px auto;
  pointer-events: none;
}

.home_card::before {
  left: 0;
}

.home_card::after {
  right: 0;
}

.MuiPaper-root.card_token_bg::before,
.card_token_bg::before {
  left: 0;
  width: 10px;
}

.MuiPaper-root.card_token_bg::after,
.card_token_bg::after {
  right: 0;
  width: 10px;
}

.home_card h2 {
  font-size: 2.5rem;
  margin: 0 0 -1.25rem 0;
  color: #e30333;
}

.modal-zap-estimate {
  margin: 1rem 0 -1rem 0;
}

.modal-zap-estimate p {
  margin: 0;
  text-align: left;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.modal-zap-estimate-input {
  background-color: #021130;
  border-radius: 12px;
  padding: 0.5rem;
}

.modal-zap-estimate-input p {
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.modal_zap_select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal_account {
  color: #fff !important;
}

.text-center {
  text-align: center;
}


.modal {
  width: 400px;
  outline: none;
  padding: 32px;
  position: absolute;
  max-width: 100%;
  /* background-color: #201c76 !important; */
}

.modal h2 {
  color: #fff !important;
  text-align: center;
  margin-bottom: 1.5rem;
}

.modal button,
.modal button:hover,
.modal button:focus {
  color: #fff !important;
}

.modal button.wallet-button,
.modal button.wallet-button:hover,
.modal button.wallet-button:focus {
  padding-left: 90px !important;
  margin-bottom: 3rem;

}

.modal button img {
  width: 32px;
}

.modal .wallet-icon {
  width: 65px;
  height: 65px;
  display: flex;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  top: -5px;
}

.modal_root_styled {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
}

.modal_root_backdrop {
  background-color: rgba(0, 0, 0, .75);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.modal_root .modal {
  position: relative;
  width: auto;
}

.modal_account {
  color: #fff !important;
}


body {
  background: transparent;
}

.intro,
.intro a {
  color: #fff;
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }

  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(80px)
  }

  100% {
    transform: translateX(0px)
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}

/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}

.demo a {
  font-family: 'Raleway', sans-serif;
  color: #000;
}

.stats {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.stat {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
}

.stat1 {
  display: flex;
  margin-right: 20px;
}

.stat2 {
  display: flex;
  margin-right: 20px;
}

.stat3 {
  display: flex;
}

.stat1 img {
  margin-right: 8px;
  width: 36px;
  height: 36px;
}

.stat1 h1 {
  font-size: 15px;
  color: black;
  /* margin-top:1rem */

}

.stat2 h1 {
  font-size: 15px;
  color: black;
  /* margin-top:1rem */

}

.stat3 h1 {
  font-size: 15px;
  color: black;
  /* margin-top:1rem */

}

.stat2 img {
  margin-right: 8px;
  width: 36px;
  height: 36px;
}

.stat3 img {
  margin-right: 8px;
  width: 36px;
  height: 36px;
}

@media (max-width:540px) {
  .stat {
    flex-direction: column;
  }

  .stat img {
    margin-bottom: 6px;
  }

  .stat1 {
    margin-right: 35px;
  }

  .stat2 {
    margin-right: 35px;
  }

  .stat3 {
    margin-right: 0px;
  }
}

.heading_title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.heading {
  font-size: 50px;
  font-family: 'Amarante', cursive;
}

.btn-margin {
  margin-right: 1rem !important;
}

.heading2 {
  margin-left: 20px;
}

.number {
  color: wheat;
  font-size: 25px;
}

.tvl_amount {
  height: 80px;
  margin-left: 30px;
  border: 2px solid #dd14fd;
  /* background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important; */
  /* margin-top: 10px; */
  padding-left: 7rem;
  padding-right: 7rem;
  border-radius: 15px;
  color: wheat !important;
  font-size: 2rem;
  display: flex;
  align-self: end;
}

.btn-wallet {
  background-color: transparent !important;
  color: wheat !important;
  /* background-image: url("./assets/img/wallet.svg") !important; */
  height: 38px;
  width: 100%;
  border: 1px solid #6a0bce !important;
  border-radius: 15px !important;
}

.wheat {
  color: wheat;
}

.wheat2 {
  color: wheat !important;
  margin-bottom: 0;
}

.wid {
  width: 120% !important;
}

.active {
  color: rgba(109, 255, 191, 1) !important;
  text-shadow: 0 -2px 8px rgb(82 194 230 / 50%) !important;

}

.icon-mar {
  margin-left: 35px;
}

.icon-mar2 {
  margin-left: 80px;
}

.icon-mar3 {
  margin-left: 55px !important;
}

.icon-mar4 {
  margin-right: 55px !important;
}

.icon-mar5 {
  margin-right: 155px !important;
}

.btn-rebate {
  color: red !important;
  border: 1px solid red !important;
  padding: 1rem 2rem !important;
  background-color: transparent !important;
  cursor: pointer !important;
}

.tvl_amount span {
  font-weight: bold;
  align-self: center;
}

.tvl_amount h4 {
  align-self: center;
  margin-bottom: 0;
}

.icons-harvest img {
  z-index: 0;
  position: relative;
}

.cards_heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cards_heading img {
  width: 50px;
  height: 50px;
}

.font12 {
  font-size: 18px;
  margin-bottom: 6px;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.martop {
  margin-top: -5rem;
  position: relative;
}

.purple {
  color: #cd69df
}

.white {
  color: white
}

.font {
  font-size: 30px;
}

.center {
  text-align: center;
}

.mid-center {
  display: flex;
  justify-content: space-between;
}

/* @media (max-width:540px){
  .mid-center{
flex-direction: column;
align-items: center;
justify-content: space-between;
height:100vh
}
.cemetry_cards-1{
flex-direction: column;
align-items: center;
}
.card1{
  margin-top: 2rem;
}
.card11{
  margin-top: 3rem!important;
}
.heading{
  margin-left: 7rem!important;
}

.heading2{
  margin-left: 7rem;
}
.heading3{
  margin-left: 10rem;
  margin-top: 1rem;
}
.tvl_amount{
  margin-left: 5rem;
}
.mar-right{
  margin-right: 3rem;
}
.cemetry-small{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
 
  
}

.cemetry-small-4{
  margin-top: 1rem;
  margin-left: 2rem;
}
.ma{
  margin-top: 1rem;
}
.cemetry_cards{
  margin-top: 1rem;
}
} */
/* @media(max-width:600px){
  .ma{
    margin-top: 1rem;
  } */
/* }
@media (max-width:480px){
  .mid-center{
flex-direction: column;
align-items: center;
justify-content: space-between;
height:100vh
} */
.card1 {
  margin-top: 2rem;
}

.heading3 {
  /* margin-left: 7rem; */
  margin-top: 1rem;
}

.tvl_amount {
  margin-left: 3rem;
}

.mar-right {
  margin-right: 1.5rem;
}

.cemetry-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;


}

.cemetry-small-4 {
  margin-top: 1rem;
  margin-left: 1rem;
}

/* } */



.title_card {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 20px;
  color: white;
}

.meta {
  margin-top: 8px;
  border: 2px solid rgba(148, 1, 251, 1);
  border-radius: 15px;
  padding: 0px 5px 0px 5px;
}

.meta img {
  width: 42px;
  height: 30px;
  border: none;

}

.card1 {
  height: 440px;
  width: 300px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  border-radius: 25px;
  border: 2px solid #dd14fd;
  padding: 20px;
  display: inline-table;
}

.cemetry {
  background-image: url("../src/assets/img/cemetry.png");
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.moon {
  background-image: url("../src/assets/img/background2.png");
  background-size: cover;
}

.cemetry_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* color:white */
}

.cemetry_cards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  margin-top: 4rem;
  flex-wrap: wrap;
}

.cemetry_cards-1 {
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 270px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
  padding-bottom: 12px;
}

.cemetry_cards-11 {
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 400px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
}

.top {
  margin-top: 1rem;
}

.btn {
  border-radius: 1rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  border-color: rgba(148, 1, 251, var(--tw-border-opacity));
  background-image: linear-gradient(225deg, #10003d, #dd14fd 107.31%);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: white;
  cursor: pointer;
}

.text {
  text-decoration: none;
  color: white
}

.buy_tomb {
  margin-left: 5rem;
}

.cemetry_heading {
  color: wheat;
  font-size: 1.5rem;
}

.deposit {
  color: rgba(218, 20, 253, 1);
}

.rounded {
  border-radius: 9999px;
  background-image: linear-gradient(225deg, #160a1826, rgba(16, 0, 61, .2) 107.31%);
  position: relative;
  top: -30px
}

.cemetry_images {
  display: flex;
}

.cemetry-small {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.cemetry-small-1 {
  margin-right: 2rem;
  height: 80px;
  width: 160px;
  /* padding-top: 5rem; */
  border: 2px solid #dd14fd;
  border-radius: 30px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pad {
  padding-top: 1rem !important;
}

.heading-cemetry {
  font-size: 20px;
}

.claim {
  padding-left: 10rem;
  padding-right: 10rem;
  border-radius: 30px;
}

.cemetry-small-3 {
  margin-right: 2rem;
  height: 80px;
  width: 200px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.margintop {
  margin-top: 20px !important;
}

.margin-bottom {
  margin-bottom: 20px !important;
}

.wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: 5rem;
}

.pad2 {
  padding-left: 8rem;
  padding-right: 8rem;
  width: 50%;
  border-radius: 25px;
}

.butt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 20vh;
  margin-top: 2rem;
}

.wrapper2 {
  display: flex;
  justify-content: space-evenly;
  margin-left: 8rem;
  margin-right: 6rem;
}

.hidden {
  opacity: 0;
}

@media(max-width: 1200px) {
  .cemetry_cards-1 {
    margin: 3rem 0 0 0 !important;
    width: 100%;
    /* border: none; */
    /* margin-left: 2.5rem; */
  }

  .mar-rebeates {
    margin-left: 0 !important;
  }

  .mar-cemetry {
    /* margin-left: -1rem; */
  }

  .mar-cemetry2.mar-cemetry {
    margin: 3rem 0 0 0 !important;
  }

  .claim {
    width: auto !important;
  }

  .wrapper {
    flex-direction: column;
    /* margin-left: -2.5rem; */
    margin-left: 0;
  }

  .mid-center {
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;
    margin-left: 2rem;

  }

  .pit-center {
    margin-right: 12rem !important;
    display: block !important;
  }

  .wrapper2 {
    flex-direction: column !important;
    align-items: center !important;
    margin: 0 !important;
  }

  .bank {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

  }

  .cemetry-small-1 {
    margin-top: 1rem !important;
    margin-right: 0;
  }

  .heading3 {
    margin-right: 5rem !important;
  }

  .heading_title {
    margin: 0;
  }

  .tvl_amount {
    margin: 20px 30px;
    text-align: center;
  }

  .heading2 {
    margin: 0;
    text-align: center;
    margin-left: 0;
  }

  .heading {
    margin: 0;
  }

  .icon-mar3 {
    margin: 0 !important;
  }

  .cemetry_images {
    justify-content: center;
    width: 100%;
  }

  .cemetry-small-4 {
    margin-right: 0 !important;
    margin-left: 0;
    width: 100% !important;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 30px);
  }
}

@media (max-width:375px) {
  .tvl_amount {
    /* margin-left: 2rem; */
    padding: 0rem 2rem;
  }

  .cemetry_cards-11 {
    margin-right: 0.5rem !important;
    width: auto !important;

  }

  .font8 {
    font-size: 8px !important;
  }

  .font10 {
    font-size: 10px !important;
  }

  .font12 {
    font-size: 18px !important;
  }

  .cemetry-small-5 {
    width: 200px;
    height: auto !important;
  }
}

@media (max-width:1200px) {
  .tvl_amount {
    /* margin-left: 1rem; */
    padding: 1rem 2rem;
  }

  .font8 {
    font-size: 8px !important;
  }

  .font11 {
    font-size: 11px !important;
  }

  .wid {
    width: 50% !important;
  }

  .mar-rebeates {
    /* margin-left: 1.2rem!important; */
  }

}

.bank {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  /* margin-left: 25rem; */
}

.mask {
  cursor: pointer;
  padding: 0rem 12px;
  border: 1px solid #8570f5;
  border-radius: 10px;
}

.cemetry-small-4 {
  margin-right: 2rem;
  width: 300px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 6px;
}

.mar-cemetry2 {
  margin-right: 1rem !important;
}

.tomb {
  color: white;
  text-decoration: none;
}

.cemetry-cards {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.cemetry_cards-1 {
  margin-right: 2rem;
}

.btn-disabled {
  background: none;
  cursor: not-allowed;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  opacity: .7;
}

.buutons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.claim {
  width: 31rem
}

.background {
  background-image: url("../src/assets/img/sky.352b80b2.svg");
  background-size: cover;
  background-position: top;

}

.stars {
  background-image: url("../src/assets/img/bg-stars.5bf42f7e.svg");
  background-size: cover;
  position: absolute;
}

.margin {
  margin-left: 28rem;
  padding-left: 10rem;
  padding-right: 10rem;
}

/* .masonry-small{
  display: flex;
  width: 50%;
  justify-content: center;
} */
.masonry_cards {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.cemetry-small-4 {
  margin-right: 2rem;
  width: 300px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cemetry-small-5 {
  margin-right: 2rem;
  height: 100px !important;
  width: 300px;
  border: 2px solid #dd14fd;
  border-radius: 25px;
  background-image: linear-gradient(1turn, rgb(19 0 22 / 96%), rgb(2 3 62 / 59%)) !important;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3rem;
}

.bg1bg {
  background-image: url(./assets/img/background2.png);
  width: 100%;
}

.bg2bg {
  background-image: url(./assets/img/background1.png);
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent !important;
  color: #fff !important;
  /* background-image: url("./assets/img/wallet.svg") !important; */
  height: 36px;
  width: 100%;
  border: 1px solid #6a0bce !important;
  border-radius: 15px !important;
  margin-right: 3rem !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  color: #FFF !important;
}
@media (max-width: 1200px) {
  .dropdown {
    margin-left: 16px;    
  }
}

.dropdown2 {
  position: relative;
  display: inline-block;
  margin-left: 3rem !important;
  color: wheat !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown2-content {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown2-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown2-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown2:hover .dropdown2-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */




.dropbtn2 {
  background-color: transparent !important;
  color: wheat !important;
  /* background-image: url("./assets/img/wallet.svg") !important;  */
  height: 52px;
  width: 70%;
  border: 1px solid #6a0bce !important;
  border-radius: 15px !important;
}

#dropdown-basic {
  border-radius: 16px;
  margin-top: 8px;
  background: none;
  border-color: #571EB1;
  color: wheat !important;
}

.dropdown-menu,
.dropdown-menu:hover {
  background: none;
  background-color: transparent !important;
  color: wheat !important;
}

.dropdown-item,
.dropdown-item:focus,
.dropdown-item:hover {
  color: wheat !important;
  background-color: transparent !important;
}

.dropdown-item:hover {
  background-color: #571EB1 !important;
  opacity: 0.5;
}

.bio {
  margin-bottom: 12px;
}

.mar-rebeates {
  margin: auto;
}